@import "../../../variables.scss";
#delete-integration {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      top: 10%;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;

        .modal-header {
          padding: 30px 15px;
          color: $error-color;
          font-size: 18px;
          font-weight: bold;
          border-bottom: solid 1px $card-border-color;
          &-accept {
            color: white;
            padding: 30px 15px;
            font-size: 18px;
            font-weight: bold;
            border-bottom: solid 1px $card-border-color;
          }
        }

        .modal-body {
          border-bottom: solid 1px $card-border-color;
          .delete-message {
            color: $white-color;
          }

          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 1;
            width: 148px;
            margin-top: -4rem;
          }
        }
        .modal-footer {
          .btn {
            text-transform: uppercase;
          }

          .separator {
            width: 1px;
            height: 30px;
            background-color: $separator-color;
          }
        }
      }
    }
  }
}
