@import "../../../variables.scss";

#top-panel-main-container {
  background-color: $card-background-color;
  border-radius: 5px;
  .toggle-label {
    color: $surface80;
    vertical-align: text-top;
  }
  .sensitive-data {
    height: 100px;
    overflow: auto;
  }
  .risk-accept-toggle {
    vertical-align: middle;
    .react-toggle-track {
      background: $toggle-track;
      height: 22px;
    }
    .react-toggle-thumb {
      background-color: $toggle-button-color;
      border: none;
      box-shadow: none;
    }
  }
  .risk-accept-toggle.react-toggle--checked {
    .react-toggle-track {
      background: $toggle-track;
    }
    .react-toggle-thumb {
      background-color: $toggle-button-color;
      border: none;
      box-shadow: none;
    }
  }
  .workday-block {
    background: rgba(8, 12, 32, 0.6);
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: none;
    margin: 10px;
    padding: 10px;
    padding-top: 1.5em;
    .count-circle {
      width: 62px;
      height: 62px;
      min-width: 62px;
      background-color: #c83a39;
      border-radius: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $white-color;
      font-weight: 600;
      margin-top: 0.7rem;
    }
    .datastore-logo {
      margin-top: 10px;
      width: 40px;
    }
    .action-buttons {
      margin-top: 40px;
    }
    .policy-details-action-icons {
      circle {
        fill: $accordion-color;
      }
      path:nth-child(2) {
        fill: $accordion-color;
      }
      width: 40px;
    }
    .policy-detail-create-ticket {
      circle {
        stroke: $white-color;
      }
      path {
        fill: $white-color;
      }
      width: 40px;
    }

    .workday-heading {
      font-family: $manrope-bold-font;
      font-weight: 600;
      font-style: normal;
      font-size: 1.25rem;
      color: $surface80;
      text-align: left;
    }

    .workday-subheading {
      font-family: $manrope-bold-font;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: $surface50;
      text-align: left;
    }
    .workday-subheading-value {
      font-family: $manrope-regular-font;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: $subtext-color;
      text-align: left;
      div {
        border: none;
        border-radius: 3px;
      }
    }
    .subvalue-bold {
      font-family: $manrope-regular-font;
      font-weight: 650;
      font-style: normal;
      font-size: 0.875rem;
      color: $surface80;
      text-align: left;
    }
  }
  .sensitive-data-block {
    min-height: 232px;
    padding-top: 1.5em !important;
    // overflow-y: scroll;

    .sensitive-data-heading {
      font-family: $manrope-bold-font;
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      color: $surface50;
      text-align: left;
    }
    .sensitive-data-subheading {
      font-family: $manrope-regular-font;
      font-style: normal;
      font-size: 14px;
      text-align: left;
      span {
        font-family: $manrope-bold-font;
        font-weight: 600;
        text-decoration: underline;
        color: $surface80;
      }
    }
    .sensitive-data-record {
      border-radius: 5px;
      margin: 5px 0;
      margin-right: 5px;

      .sensitive-data-title {
        background-color: #ffffff00;
        font-family: $manrope-bold-font;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        color: $subtext-color;
        text-align: left;
      }
    }

    .scroll-overflow {
      overflow-y: auto;
      height: 200px;
    }
  }
  .more-tags {
    cursor: pointer;
  }

  .policy-details {
    &-title {
      font-size: 14px;

      color: $surface50;
      font-weight: 300;
    }
    &-description {
      font-size: 14px;

      color: $surface80;
      font-weight: 300;
    }
  }
}
.top-panel-tags-content {
  min-width: 200px;
  max-width: 330px !important;
  .header {
    background-color: #6c83bb;
    padding: 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
}
.text-decoration-underline {
  text-decoration: underline;
}

.font-color-glaucous {
  color: $surface80 !important;
}
.card-text {
  font-size: 14px;
}
.card-subtext {
  font-size: 12px;
}
.btn-security-issues {
  font-size: 12px;
  &:hover {
    border: 1px solid $surface80;
  }
}
.card-tabs {
  &-disabled {
    border-radius: 16px;
    color: $white-color !important;
    background-color: $surface80 !important;
    border-color: $surface80 !important;
    opacity: 0.3;
    cursor: default;
  }
  border-width: 0px;
  left: 0px;
  top: 0px;
  color: $white-color;
  padding-right: 2px;
  padding-left: 2px;
  background: inherit;
  background-color: $surface80;
  border: none;
  border-radius: 16px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}
