@import "../../variables.scss";

.reports-container {
  min-height: 100vh;
  margin-right: 0.5rem;
  .action-btn {
    border: none;
    height: 35px;
    padding: 10;
    color: $white-color;
    font-weight: bold;
    margin-left: 10px;
  }
  .report-title {
    span {
      color: $card-heading-color;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 10px 0;
    }
  }

  .reports-body {
    h5 {
      color: $surface80;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    h6 {
      color: $manatee-shade1;
      margin-top: 5px;
      font-weight: 500;
      font-size: 16px;
    }
    .scheduler-btn {
      background-color: $surface80;
      border: none;
      height: 35px;
      padding: 10;
      color: $white-color;
      font-weight: bold;
    }

    .table-responsive {
      table {
        thead {
          .head {
            background-color: rgba(0, 0, 0, 0.0196078431372549);
            color: $subtext-color;
            padding: 20px !important;
          }
        }
        tbody {
          .body {
            background: $ticket-tbody;
            padding: 20px !important;
            td {
              color: $card-border-color-2;
              font-size: 12px;
            }
            td:nth-child(1) {
              background: $surface80;
              color: $white-color;
              font-size: 20px;
            }
            td:nth-child(2) {
              color: $surface80;
            }
            td:nth-child(3) {
              color: $surface80;
              i {
                color: $surface80;
                font-size: 20px;
              }
            }
            td:nth-child(7) {
              span {
                border-radius: 50%;
                width: 50px;
                color: $surface80;
                i {
                  font-size: 20px;
                  margin: 0px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
